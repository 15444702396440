<template>
	
	<div class="d-inline-block" @click.stop="row.toggleDetails">
		<button v-if="row.detailsShowing" class="btn btn-secondary btn-sm">
			<font-awesome-icon :icon="['fal', 'times']" /> {{ $t('table.general.close') }}
		</button>
		<button v-else  class="btn btn-secondary btn-sm">
			<font-awesome-icon :icon="['fal', 'plus']" /> {{ $t('table.general.plus_infos') }}
		</button>
	</div>
</template>

<script>
export default {
	name: 'RowDetailsButton',
	props: {
		row: Object,
		columns: Array
	}
}
</script>
